import React,{useEffect} from 'react'
import BrowserPojects from './BrowserPojects'
import BrowserMusic from './BrowserMusic'

const BrowserContent = (props) => {
    const view = props.view
    const project = props.project
    useEffect(()=>{
        // console.log("BrowserContent:",project)
    },[project,view])
  return (
    <>
       {view === 'projects' &&( project?.type !== 'Parallax' ) && <BrowserPojects project={project}/>}
       {view === 'projects' && ( project?.type === 'Parallax' ) && <iframe style={{width:'100%',height:'300px',margin:'auto'}} src={project?.url}/>}
        {view === 'music' && <BrowserMusic project={project}/>}
    </>
  )
}

export default BrowserContent