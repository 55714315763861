import React,{useEffect, useState, useRef, useContext} from 'react'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import '../styles.css';

// import required modules
import { EffectCoverflow, Pagination } from 'swiper/modules';
import { Box, Container, Typography } from '@mui/material';

import { AppContext } from '../context/AppProvider';
import MusicCard from './MusicCard';
const BrowserMusic = (props) => {
 
    const p = props.project
    const {isFullView, setIsFullView ,fullSrc, setFullSrc } = useContext(AppContext)
 
const generatedSlides = () =>{
    let slides = []

    for(let i =0; i < p?.tracks?.length ; i++){
        // slides?.push(<ProjectCard key={i} index={i}/>)
        slides?.push(
            <SwiperSlide key={i} sx={{padding:'0.5rem',cursor:'pointer'}}>
                <MusicCard album={p} key={i} index={i}/>
            </SwiperSlide>
        )
    }
    // console.log('generatedSlides:',slides)
    return slides
}
useEffect(()=>{

},[fullSrc, isFullView])
useEffect(()=>{

      console.log("----BrowserMusic----:",p) 
},[])
  return (
        
    <Box sx={{ position: 'absolute',top:'-24.5%', left:'-27%',height:'fit-content', width:'550px', 
        margin:'auto',transform:'scale(0.67)' }}>
   {p?.tracks?.length > 0 && <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
      //  sx={{height:'100%'}}
      >
        {/* <div style={{height:'100%'}}>&nbsp;</div> */}
        {p?.tracks?.length > 0 && generatedSlides()?.map((s,i)=>{return s})}
        {/* {p?.pics?.length === 0 && generatedSlides()?.map((s,i)=>{return s})} */}


       

      </Swiper>}
     {/* {p?.pics?.length > 0  && <Box sx={{position: 'absolute',bottom:'-20px',left:'50%',transform:'translate(-50%,0)',backgroundColor:'rgba(255,255,255,0.3)',
          backdropFilter:'blur(5px)', height:'2rem', width:'70%',borderRadius:'5px',zIndex:99999, boxShadow:'0px 0px 10px 5px rgba( 0, 0, 0, 0.5)'}}>
        &nbsp;
       </Box>} */}
     </Box>
    // </Box>
  )
}

export default BrowserMusic