import React,{useEffect, useState, useRef, useContext} from 'react'

import { AppContext } from '../context/AppProvider';
import { Box, Stack, Tooltip } from '@mui/material';
import * as Brands from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MusicCard = (props) => {
    const {isFullView, setIsFullView ,fullSrc, setFullSrc } = useContext(AppContext)

  const p = props.album

    const brands = ['facebook','instagram','linkedin','upwork', 'github', 'gitlab', 'twitter', 'bandcamp','soundcloud','spotify','youtube','vimeo','amazon','tidal','youTube',
        'airbnb','uber','lyft','stackoverflow','stackexchange','pinterest','skype','etsy','codepen', 'dropbox', 'googleplay','imdb','npm','meetup','itunes','cashApp','venmo','paypal','pandora','deezer','linktree','xbox','playstation','steam','snapchat','slack','kickstarter','patreon','stripe', 
        'discord','wordpress','tiktok','whatsapp',
        'windows','apple','shopify','twitch','tumblr','trello','unity','wikipedia'
    ]

    function hasValue(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== undefined && obj[key] !== null  && obj[key] !== '') {
      return true;
    }
  }
  return false;
}

const linkIconCheck = (l) =>{
        // console.log('[linkIconCheck] brands?.some((k)=>l?.includes(k.toString))',brands?.find((k)=>l?.includes(k?.toString())))
        // console.log('[linkIconCheck] l',l)
        if(l){
          let result = brands?.find((k)=>Object.keys(l)?.includes(k?.toString()))
         
          if(result){
              return result?.charAt(0).toUpperCase() + result?.slice(1);
          }else if(l?.includes('youtu.be') || l?.includes('youtube')){
            return 'Youtube'
          }else{ return false}
        }else {return}
    }
  
  return (
    <div style={{display:'flex',color:"#fff", gap:'0.5rem', width:'auto',maxHeight:'320px'}}>
      <div style={{ borderRadius:'10px',display:'flex', jusstifyContent:'center',
        overflow:'hidden',width:'auto',height:'height',textAlign:'center',
        flexDirection:'column',alignItems:'center',
        // padding:'0.5rem',
        border:'2px solid #fff', boxShadow:'0px 0px 10px 5px rgba(255,255,255,0.5)'
        }}>
          <img src={p?.cover_pic} onClick={(e)=>{
              console.log(e.target.src)
              setFullSrc(e.target.src.toString())
              setIsFullView(!isFullView)
          }}/>
    {/* <Stack sx={{height:'2rem'}}> */}

        {hasValue(p?.stream_links) && (<Box sx={{position:'absolute',
                  top:'0.25rem',
                  display:'flex',
                  // border:'1px solid red', 
                      minHeight:'1.5rem',
                      alignContent:'center',
                      gap:'0.5rem',
                      padding:'0.125rem',
                      // justifyContent:'center',
                      zIndex:9999999999,
                      width:'90%', margin:'auto'}}>

                  {Object.keys(p?.stream_links).map((l,j)=>{
                    
                    let link = p?.stream_links[`${l}`]
                    if(hasValue(link)){
                      // console.log('[profile] l:', l)
                      // console.log('[profile] link:', link)
                          let iconName = linkIconCheck(link)
                          // console.log('iconName:', iconName)
                          return (
                              <Tooltip title={iconName} key={j}>

                                  <div
                                  style={{
                                      // display:'flex',
                                      color:'#000',  
                                      width:'1.5rem',height:'1.5rem',
                                      '&:hover': {background:'rgba(144, 254, 195,0.5)'},
                                      // border:'1px solid blue', 
                                      borderRadius:'3px',
                                      alignContent:'center',
                                      minWidth:'1rem',minHeight:'1rem'}} //key={i}
                                      
                                      key={j}
                                      > 
                                  <a 
                                  style={{
                                      // display:'flex',
                                      color:'#000',  
                                  
                                      // border:'1px solid blue', 
                                      borderRadius:'3px',
                                      alignContent:'center',
                                      minWidth:'1rem',minHeight:'1rem'}} //key={i}
                                      href={link[0]}
                                      target='_blank'
                                      key={j}
                                      > 
                                      {iconName !== 'Pandora' && <FontAwesomeIcon icon={Brands['fa'+ iconName]} key={j} />}
                                      {iconName === 'Pandora' && <img style={{height:'1.2rem'}}
                                      src={'/assets/pandora-icon.svg'} key={j} alt='pandora' />}
                          
                                  </a>
                                  </div>
                              </Tooltip>
                          )
                      }
      

              })}
          </Box>)
              }
          <div style={{position:'absolute',bottom:'-1.5rem', color:'#000', background: 'rgb(255,255,255)',
          background: `linear-gradient(0deg, rgba(255,255,255,1) 0%, 
          rgba(255,255,255,1) 48%, rgba(255,255,255,0) 94%)`,height:'5rem',
              width:'100%', borderRadius:'0 0 5px 5px'}}>

            <span style={{position:'absolute',bottom:'0.5rem',left:'50%',transform:'translate(-50%,0)', color:'#000',
              width:'100%'}} >{props.index + 1} - {p?.tracks[props.index]}</span>
          </div>
    {/* </Stack> */}
        </div>
      <div>
          {/* { p?.name} */}
      </div>   
  </div>
  )
}

export default MusicCard