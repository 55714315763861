import React, {useContext, useEffect, useState} from 'react'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import { AppContext } from "../context/AppProvider";
// import mus_url from '../audio/04-AJPHNX- REAPER_Key_Of_Me_live.mp3'
// import mus_url from '../audio/AJPHNX-Word_of_Mouth.mp3'


// import 'react-h5-audio-player/lib/styles.less' Use LESS
// import 'react-h5-audio-player/src/styles.scss' Use SASS

const mus_url =['https://pub-858313b746d04d3aabd579ad9c0b357a.r2.dev/AJPHNX-Word_of_Mouth.mp3',
'https://pub-858313b746d04d3aabd579ad9c0b357a.r2.dev/04-AJPHNX- REAPER_Key_Of_Me_live.mp3']
// const mus_url = '../audio/04-AJPHNX- REAPER_Key_Of_Me_live.mp3'

const tracks = [
    {'Word of Mouth':'https://pub-858313b746d04d3aabd579ad9c0b357a.r2.dev/AJPHNX-Word_of_Mouth.mp3'},
    {'Key of Me (Instrumental)':'https://pub-858313b746d04d3aabd579ad9c0b357a.r2.dev/04-AJPHNX- REAPER_Key_Of_Me_live.mp3'},
    {'On Releasing':'https://pub-858313b746d04d3aabd579ad9c0b357a.r2.dev/On Releasing (Prog).mp3'},
    {'The Parallaxr':'https://pub-858313b746d04d3aabd579ad9c0b357a.r2.dev/01 The Parallaxr (Instrumental).mp3'}


]


const Player = () => {
    const {appData,updateAppData, isAudioOpen,setIsAudioOpen, isMuted,} = useContext(AppContext);
    const [trkNum, setTrkNum ] = useState(0)
    
    const compStyles = {
        position:'relative',
        // position:'absolute',
        // top:'4.5rem',
        // left:'50%',
        // transform: 'translate(-50%,0)',
        zIndex: isAudioOpen?9:0,
        border: '3px solid #000',
        borderRadius:'10px',
        width: '100%',
        maxWidth:'350px',
        boxShadow:'0px 0px 10px 5px rgba( 0, 0, 0, 0.3)',
        // bottom: isAudioOpen?'0':'500px',
        opacity: isAudioOpen?'1':'0',
        display: isAudioOpen?'':'none',
        visibility: isAudioOpen?'':'hidden',
        // fontSize: '0.75rem',
        // fontFamily:'fugaz One'
    }
    
    useEffect(()=>{
        
    },[isAudioOpen,isMuted,trkNum])

    let trackName = Object.keys(tracks[trkNum]).toString()
    // console.log ("music title:", trackName)
    // console.log ("music link:",tracks[trkNum][trackName])

    return(
        <div style={compStyles} >
            <AudioPlayer
                style={{borderRadius:'10px'}}
                autoPlay
                showSkipControls
                onClickNext={(e)=>{
                    if( trkNum < tracks?.length -1 ){
                        setTrkNum(trkNum+1)
                    }else{
                        setTrkNum(0)
                    }
                }}
                onClickPrevious={(e)=>{
                    if( trkNum >0 ){
                        setTrkNum(trkNum-1)
                    }else{
                        setTrkNum(tracks?.length -1 )
                    }
                    }
                }
                autoPlayAfterSrcChange 
                src={tracks[trkNum][trackName]}
                muted={isMuted}
                // preload='auto'
                onLoadStart={e =>{ 
                    // setTrkNum(1)
                    console.log("[ -----Audio------- ] load start:",e)}}
                // onLoadedMetaData ={e => console.log("[ -----Audio------- ]loadedMeta data:",e)}
                loadedData={e => console.log("[ -----Audio------- ]loaded datat:",e)}
                // onPlay={e => console.log("[ -----Audio------- ]onPlay",e)}
                onLoadEnd={e => console.log("[ -----Audio------- ]load end:",e)}
                onCanPlay={e => console.log("[ -----Audio------- ]onCanPlay",e)}
                showDownloadProgress ={true}
                onEnded={e=>{   if( trkNum < tracks?.length -1 ){
                        setTrkNum(trkNum+1)
                    }else{
                        setTrkNum(0)
                    }}}
                onError={e => console.log("[ -----Audio------- ]Error: ",e)}
                crossOrigin = 'anonymous'
                header={`AJPHNX - ${Object.keys(tracks[trkNum])}`}
                // other props here
            />
        </div>
)};
export default Player